import { RequestService } from "@/api/instance";
import { DeAuthSession } from "@/api/de-auth";

export const GetResponse = async id => {
  try {
    let {
      data: { proposal }
    } = await RequestService().get(`/proposal/${id}`);
    return proposal;
  } catch (e) {
    DeAuthSession(e);

    throw e;
  }
};

export const AcceptResponse = async responseID => {
  try {
    let { data: res } = await RequestService().put(
      `/proposal/${responseID}/accept`
    );
    return res;
  } catch (e) {
    DeAuthSession(e);

    throw e;
  }
};

export const DeclineResponse = async responseID => {
  try {
    await RequestService().put(`/proposal/${responseID}/decline`);
  } catch (e) {
    DeAuthSession(e);

    throw e;
  }
};
