<template>
  <div class="accepted-container">
    <div class="company-info scroll-shadow">
      <v-img
        height="300px"
        class="company-logo"
        position="center,center"
        contain
        :src="response.company_info.logo"
      >
        <v-container fill-height fluid class="nopad">
          <v-layout fill-height>
            <v-flex xs12 align-content-end justify-end flexbox>
              <div class="titleContainer">
                <div
                  style="background-color: rgba(0, 0, 0, 0.51); padding: 10px"
                >
                  <h1 class>{{ response.company_info.company_name }}</h1>
                </div>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-img>

      <p class="header"><strong>Bio</strong></p>
      <p class="multi-line">
        {{ response.company_info.bio }}
      </p>
      <div class="split-field">
        <div>
          <p class="header" v-if="response.company_info.website != null">
            <strong>Website</strong>
          </p>
          <p v-if="response.company_info.website != null">
            <a :href="response.company_info.website">
              {{ response.company_info.website }}
            </a>
          </p>
        </div>
        <div>
          <p class="header">
            <strong>CQC Profile</strong>
          </p>
          <p>
            <a :href="response.company_info.cqc_profile">
              {{ response.company_info.cqc_profile }}
            </a>
          </p>
        </div>
      </div>

      <div
        v-if="response.company_info.cqc_rating != null && show_cqc"
        style="margin-top: 20px"
      >
        <cqc-profile
          v-if="response.company_info.cqc_rating"
          :info="response.company_info.cqc_rating"
        ></cqc-profile>
      </div>
    </div>

    <div class="proposal">
      <p class="header"><strong>Introduction</strong></p>
      <p class="multi-line intro scroll-shadow">
        {{ response.intro }}
      </p>
      <div class="split-field">
        <div>
          <p class="header" v-if="response.cost">
            <strong>Cost</strong>
          </p>
          <p v-if="response.cost">{{ response.cost }}</p>
        </div>
        <div>
          <p class="header" v-if="response.start_date != null">
            <strong>Proposed Start Date</strong>
          </p>
          <p v-if="response.start_date != null">
            {{ new Date(response.start_date).getDate() }}/{{
              new Date(response.start_date).getMonth() + 1
            }}/{{ new Date(response.start_date).getFullYear() }}
          </p>
        </div>
        <div>
          <p class="header" v-if="response.start_time">
            <strong>Proposed Start Time</strong>
          </p>
          <p v-if="response.start_time">{{ response.start_time }}</p>
        </div>
      </div>
      <v-spacer></v-spacer>
      <div class="mb-6 mt-3" v-if="!response.accepted && !hide">
        <p>Please click on one of the following buttons to either:</p>
        <ol>
          <li>CONNECT (Exchange details with the company)</li>
          <li>DECLINE (Do not connect with the company)</li>
          <li>NOT SURE (Review other offers before deciding)</li>
        </ol>

        <br />
        <v-layout
          xs="4"
          m="12"
          justify-center
          align-center
          v-bind="button_layout"
        >
          <v-btn
            style="margin: 6px"
            color="secondary"
            :disabled="responseButtons"
            @click="acceptResponse"
            >Connect</v-btn
          >
          <v-btn
            style="margin: 6px"
            color="red"
            :disabled="responseButtons"
            @click="declineResponse"
            >Decline</v-btn
          >
          <v-btn
            style="margin: 6px"
            :disabled="responseButtons"
            @click="decideLater"
            >Not Sure</v-btn
          >
        </v-layout>
      </div>
      <div v-if="accepted" class="full-width contact-details">
        <h3>Contact Details</h3>
        <p>
          <strong>Reference Code</strong>
          : {{ response.reference }}
        </p>
        <p>
          <strong>Telephone Number</strong>
          : {{ response.company_info.tel }}
        </p>
        <p>
          <strong>Email Address</strong>
          : {{ response.contact_email }}
        </p>
        <p>
          <strong>What to do now</strong>: Your contact details will now be
          passed to this company so they can get in touch with you. Should you
          wish to contact them first you can do so using the email address or
          telephone number above quoting the reference code
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GetResponse,
  AcceptResponse,
  DeclineResponse
} from "@/api/requests/response";
// import CqcProfile from "@/components/cqc-profile.vue";
import { SHOW_CQC } from "@/config";

export default {
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  // components: { CqcProfile },
  data() {
    return {
      show_cqc: SHOW_CQC,
      responseButtons: false,
      hide: false,
      accepted: false,
      response: {
        company_info: {
          company_name: ""
        }
      }
    };
  },
  async beforeMount() {
    this.response = await GetResponse(this.id);
    this.accepted = this.response.accepted;
  },
  methods: {
    async acceptResponse() {
      this.responseButtons = true;
      try {
        let data = await AcceptResponse(this.response.id);
        this.accepted = true;
        this.response.email = data.email;
        this.response.reference = data.reference;
        this.response.tel = data.tel;
        this.hide = true;
      } catch (e) {
        this.responseButtons = false;
        throw e;
      }
    },
    async declineResponse() {
      this.responseButtons = true;
      try {
        await DeclineResponse(this.response.id);
        this.$router.push(`/home/requests`);
        this.hide = true;
      } catch (e) {
        this.responseButtons = false;
        throw e;
      }
    },
    decideLater() {
      this.$router.push(`/home/responses/${this.response.enquiry_id}`);
    }
  },
  computed: {
    button_layout() {
      const binding = {};
      if (this.$vuetify.breakpoint.xs) binding.column = true;

      return binding;
    }
  }
};
</script>

<style scoped>
.accepted-container {
  display: grid;
  grid-template-areas: "proposal info" "proposal info";
  grid-template-columns: 1fr 0.5fr;
  grid-template-rows: 300px 550px;
  gap: 20px;
}
.accepted-container > * {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 10px 10px 0 10px;
}

.company-info {
  grid-area: info;
  overflow-y: auto;
}

.intro {
  height: fit-content;
  max-height: 500px;
  overflow-y: auto;
}

.proposal {
  grid-area: proposal;
  display: flex;
  flex-direction: column;
}

p {
  padding: 0;
  margin: 0;
}
.full-width {
  width: 100%;
}
.split-field {
  display: flex;
  gap: 50px;
  margin-bottom: 20px;
}
.contact-details {
  padding: 20px;
  background-color: #def8e0;
  margin-bottom: 20px;
}
.contact-details p {
  margin: 4px;
}

.contact-details p:last-of-type {
  margin: 0px;
  margin-left: 4px;
}
.header {
  padding-top: 10px;
  font-size: 1.1rem;
}
.titleContainer {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-content: flex-end;
  color: white;
  background-color: rgba(200, 200, 200, 0.1);
}
.nopad {
  padding: 0;
}
.multi-line {
  white-space: pre-wrap;
}

.scroll-shadow {
  background: linear-gradient(#ffffff 33%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), #ffffff 66%) 0 100%,
    radial-gradient(
      farthest-side at 50% 0,
      rgba(34, 34, 34, 0.5),
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(34, 34, 34, 0.5),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  background-size: 100% 24px, 100% 24px, 100% 8px, 100% 8px;
}

@media screen and (max-width: 1280px) {
  .accepted-container {
    grid-template-rows:
      300px
      250px;
  }
  .intro {
    height: 200px;
  }
}

/* @media screen and (min-width: 1400px) {
  .response-container {
    width: 65%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 2000px) {
  .response-container {
    width: 40%;
    margin: 0 auto;
  }
}  */
</style>
